import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/yogurtandjam/Pinwheel/uikit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { UIKitProvider, Icon } from "./index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "icon"
    }}>{`Icon`}</h1>
    <Props of={Icon} mdxType="Props" />
    <UIKitProvider mdxType="UIKitProvider">
    add
    <Icon icon={'add'} size={100} mdxType="Icon" />
    key
    <Icon icon={'key'} size={100} mdxType="Icon" />
    people
    <Icon icon={'people'} size={100} mdxType="Icon" />
    workspace
    <Icon icon={'workspace'} size={100} mdxType="Icon" />
    testConsole
    <Icon icon={'testConsole'} size={100} mdxType="Icon" />
    platforms
    <Icon icon={'platforms'} size={100} mdxType="Icon" />
    employers
    <Icon icon={'employers'} size={100} mdxType="Icon" />
    admins
    <Icon icon={'admins'} size={100} mdxType="Icon" />
    </UIKitProvider>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      